import decodeJwt from 'jwt-decode'
import { jwtStorageKey } from '../constants'

const useUser = () => {
  let id = null
  const storedProfile = window.localStorage.getItem(jwtStorageKey)
  if (!storedProfile) return { id: null }

  try {
    id = decodeJwt(storedProfile).sub
  } catch (e) {
    console.log(e)
  }

  return {
    id
  }
}

export default useUser
