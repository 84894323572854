import React from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { getResources, MenuItemLink } from 'react-admin'
import { withRouter } from 'react-router-dom'
import DefaultIcon from '@material-ui/icons/ViewList'
import SettingsIcon from '@material-ui/icons/Settings'

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  return (
    <div style={{
      marginTop: 30
    }}
    >
      {resources
        .filter(resource => resource.hasList)
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options.label || resource.name}
            onClick={onMenuClick}
            leftIcon={
              resource.icon ? <resource.icon /> : <DefaultIcon />
            }
            style={{
              textTransform: 'capitalize'
            }}
            sidebarIsOpen={open}
          />
        ))}
      <MenuItemLink
        to='/settings'
        primaryText='Settings'
        leftIcon={<SettingsIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
