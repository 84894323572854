import { EDeliveryCompanyDelivererStatus } from './enums/deliveryCompanyDelivererStatus'

export const statuses = [
  {
    id: EDeliveryCompanyDelivererStatus.PENDING_INVITATION,
    name: 'Pending invitation'
  },
  {
    id: EDeliveryCompanyDelivererStatus.PENDING_APPROVAL,
    name: 'Pending approval'
  },
  {
    id: EDeliveryCompanyDelivererStatus.DECLINED,
    name: 'Declined'
  },
  {
    id: EDeliveryCompanyDelivererStatus.ACCEPTED,
    name: 'Accepted'
  }
]

export const jwtStorageKey = 'buzzin-delivery-jwt'
