import React from 'react'
import { useLogin, useNotify, Notification, useTranslate, TextInput, required } from 'react-admin'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Logo from '../../elements/Logo'
import Footer from '../../elements/Footer'
import { Form } from 'react-final-form'
import {
  useHistory
} from 'react-router-dom'
import { debounce } from 'lodash'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    paddingBottom: 60,
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  forgot: {
    margin: theme.spacing(1, 0, 2)
  }
}))

const LoginPage = (props) => {
  const classes = useStyles()
  const login = useLogin()
  const notify = useNotify()
  const translate = useTranslate()
  const history = useHistory()
  const submit = (values) => {
    login({
      username: values.username,
      password: values.password
    })
      .catch(() => notify('messages.login.invalidEmailOrPassword'))
  }
  const forgotPassword = (e) => {
    e.preventDefault()
    history.push('/forgotPassword')
  }

  return (
    <MuiThemeProvider theme={props.theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <Logo big light />
            <Form
              className={classes.form}
              onSubmit={submit}
            >
              {props => (
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className={classes.form}
                >
                  <TextInput
                    variant='outlined'
                    margin='none'
                    fullWidth
                    id='username'
                    label='Email Address'
                    name='username'
                    type='email'
                    validate={required('messages.login.email.required')}
                    autoFocus
                  />
                  <TextInput
                    variant='outlined'
                    margin='none'
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    validate={required('messages.login.password.required')}
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={debounce(props.handleSubmit, 300)}
                    disabled={props.submitting || props.invalid}
                  >
                    Sign In
                  </Button>
                  <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    className={classes.forgot}
                    onClick={forgotPassword}
                  >
                    Forgot Password?
                  </Button>
                  <Grid container>
                    <Grid
                      item
                      xs
                    >
                      {translate('loginPage.text')}
                      <a
                        style={{ color: '#fff', margin: '0 3px' }}
                        href={translate('messages.footer.privacyPolicyLink')}
                      >
                        {translate('messages.footer.privacyPolicy')}
                      </a>
                      {translate('loginPage.and')}
                      <a
                        style={{ color: '#fff', margin: '0 3px' }}
                        href={translate('messages.footer.termsAndConditionsLink')}
                      >
                        {translate('messages.footer.termsAndConditions')}
                      </a>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
            <Notification />
          </div>
        </div>
      </Container>
      <Footer />
    </MuiThemeProvider>
  )
}

export default LoginPage
