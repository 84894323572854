import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useDataProvider } from 'react-admin'

import useUser from '../hooks/useUser'

const useInitCompanyId = () => {
  const dataProvider = useDataProvider()
  const { id } = useUser()
  const dispatch = useDispatch()

  useEffect(() => {
    const initCompanyId = async () => {
      const { data: user } = await dataProvider.getOne('users', { id })
      dispatch({ type: 'COMPANY_SET', companyId: user.companyId })
    }
    if (id) {
      initCompanyId().catch(console.error)
    }
  }, [dataProvider, dispatch, id])
}

export default useInitCompanyId
