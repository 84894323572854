import { useQuery } from 'react-admin'
import useUser from './useUser'

const useDeliveryCompany = () => {
  const {
    id
  } = useUser()

  const { data: deliveryAdminData } = useQuery({
    type: 'getOne',
    resource: 'deliveryAdmins',
    payload: { id }
  })

  const { data } = useQuery({
    type: 'getList',
    resource: 'deliveryCompanies',
    payload: { id: deliveryAdminData?.deliveryCompanyId }
  })

  return {
    data: data?.[0]
  }
}

export default useDeliveryCompany
