/* global process */
import feathers, { rest, authentication } from '@feathersjs/client'
import hooks from './hooks'
import { jwtStorageKey } from '../constants'

const app = feathers()

const restClient = rest(process.env.REACT_APP_API_URL)

app.configure(restClient.fetch(window.fetch))

app.configure(authentication({
  jwtStrategy: 'jwt',
  storageKey: jwtStorageKey,
  storage: window.localStorage,
  header: 'authorization'
}))

app.hooks(hooks)

export default app
