import React from 'react'
import { AppBar, Layout as RALayout } from 'react-admin'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Footer from '../elements/Footer'

import useInitCompanyId from './useInitCompanyId'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
})

const Layout = (props) => {
  const classes = useStyles()
  useInitCompanyId()

  return (
    <>
      <RALayout
        {...props}
        style={{
          marginBottom: 60,
          minHeight: 'calc(100vh - 60px)'
        }}
        appBar={(appBarProps) => (
          <AppBar {...appBarProps}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='h6'
                color='inherit'
                className={classes.title}
                style={{ marginRight: 20 }}
                id='react-admin-title'
              />
            </div>
            <span className={classes.spacer} />
          </AppBar>
        )}
      />
      <Footer />
    </>
  )
}

export default Layout
