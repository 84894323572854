import Typography from '@material-ui/core/Typography'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import usePassword from '../hooks/usePassword'
import useStyles from '../theme'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const ChangePassword = () => {
  const {
    loading,
    savePassword,
    password,
    setPassword,
    repeatPassword,
    setRepeatPassword
  } = usePassword()
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardContent>
        <form noValidate>
          <Typography variant='h5' style={{ marginBottom: 10 }}>
            Change Password
          </Typography>
          <div>
            <TextField
              variant='filled'
              margin='normal'
              required
              id='password'
              label='Password'
              name='password'
              type='password'
              value={password}
              className={classes.input}
              onChange={e => setPassword(e.target.value)}
              autoFocus
            />
          </div>
          <div>
            <TextField
              variant='filled'
              margin='normal'
              required
              id='repeatPassword'
              label='Confirm Password'
              name='repeatPassword'
              type='password'
              value={repeatPassword}
              className={classes.input}
              onChange={e => setRepeatPassword(e.target.value)}
              autoFocus
            />
          </div>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={savePassword}
            disabled={loading}
          >
            Change password
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

export default ChangePassword
