import Typography from '@material-ui/core/Typography'
import React from 'react'
import { CloudinaryContext, Image as CRImage } from 'cloudinary-react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import useStyles from '../theme'
import useDeliveryCompany from '../../../hooks/useDeliveryCompany'

const CompanyDetail = ({ name, value }) => {
  return (
    <Typography variant='p' component='div' style={{ marginBottom: 20, display: 'flex' }}>
      <span
        style={{
          width: '100%',
          maxWidth: 200,
          display: 'inline-block'
        }}
      >
        {name}:
      </span>
      <span>
        {value}
      </span>
    </Typography>
  )
}

const Image = ({ publicId }) => {
  if (!publicId) {
    return (
      <span>No picture</span>
    )
  }

  return (
    <CloudinaryContext
      style={{
        display: 'inline-block'
      }}
      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
    >
      <CRImage width={200} publicId={publicId} />
    </CloudinaryContext>
  )
}

const CompanyDetails = () => {
  const classes = useStyles()
  const {
    data
  } = useDeliveryCompany()
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          variant='h5'
          component='h2'
          style={{
            marginBottom: 30
          }}
        >
          Company Details
        </Typography>
        <CompanyDetail name='Company Logo' value={<Image publicId={data?.photo?.publicUrl} />} />
        <CompanyDetail name='Name' value={data?.name} />
      </CardContent>
    </Card>
  )
}

export default CompanyDetails
