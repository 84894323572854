import englishMessages from 'ra-language-english'

const messages = {
  footer: {
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicyLink: '#',
    termsAndConditionsLink: '#',
    site: 'www.buzzin.ae',
    email: 'support@buzzin.ae'
  },
  settings: {
    subscription: 'To change subscription contact your reseller (sales@buzzin.ae)',
    passwordsRequired: 'Password and Confirm Password are required',
    passwordsMatch: 'Password doesn\'t match',
    passwordSave: 'Password is changed',
    nameRequired: 'Name is required',
    emailRequired: 'Email is required',
    userDetailsSave: 'User details are changed'
  },
  drivers: {
    imported: 'Imported all drivers',
    resendInvitation: 'Resent invitation',
    resendInvitationError: 'Cannot resent invitation for host'
  },
  resetPassword: {
    email: {
      required: 'Email address is required',
      invalid: 'Email address is invalid'
    },
    token: {
      required: 'Reset token is required'
    },
    newPassword: {
      required: 'New password is required'
    },
    invalid: 'Form is not valid',
    passwordResetSuccessfully: 'Your password has been reset successfully!'
  },
  login: {
    email: {
      required: 'Email address is required'
    },
    password: {
      required: 'Password is required'
    },
    invalidEmailOrPassword: 'Invalid email address or password'
  },
  validation: {
    mobilePhone: 'Not valid phone number'
  }
}

const loginPage = {
  text: 'By signing in you are accepting the',
  and: 'and'
}

export default {
  ...englishMessages,
  messages,
  loginPage
}
