import React, { useCallback, useEffect, useState } from 'react'
import {
  Create,
  CreateButton,
  Datagrid,
  DeleteButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useMutation,
  useNotify,
  AutocompleteInput
} from 'react-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import debounce from 'lodash/debounce'

import SendIcon from '@material-ui/icons/Send'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckIcon from '@material-ui/icons/Check'

import client from '../../client/feathersClient'
import LoadingButton from '../../elements/LoadingButton'
import { statuses } from '../../constants'
import useDeliveryCompany from '../../hooks/useDeliveryCompany'
import ImportButton from '../../elements/ImportButton'
import { EDeliveryCompanyDelivererStatus } from '../../enums/deliveryCompanyDelivererStatus'
import validator from 'validator/es'
import toRegex from '../../utils/toRegex'

export const validatePhone = () =>
  value => validator.isMobilePhone(value || '', 'any', { strictMode: true }) ? undefined : 'messages.validation.mobilePhone'

const ResendInviteButton = ({ record = {} }) => {
  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  let resendInvite = async () => {
    try {
      setLoading(true)
      await client.service('deliveryInvitations').create({ deliveryCompanyDelivererId: record.id })
      notify('messages.drivers.resendInvitation')
      setLoading(false)
    } catch (error) {
      console.error(error)
      notify('messages.drivers.resendInvitationError')
      setLoading(false)
    }
  }

  resendInvite = useCallback(debounce(resendInvite, 300), [record, notify])

  const canResendInvite = record.status === EDeliveryCompanyDelivererStatus.PENDING_INVITATION

  return canResendInvite
    ? (
      <LoadingButton label='Resend' onClick={resendInvite} loading={loading}>
        <SendIcon />
      </LoadingButton>
      )
    : null
}

const AcceptButton = ({ resource, record = {} }) => {
  const notify = useNotify()
  const [accept, { loading, error }] = useMutation({
    type: 'update',
    resource,
    payload: {
      id: record.id,
      data: {
        status: EDeliveryCompanyDelivererStatus.ACCEPTED
      }
    }
  })

  const acceptHost = debounce(accept, 300)

  useEffect(() => {
    if (error?.message) {
      console.error(error)
      notify(error.message, 'warning')
    }
  }, [error, notify])

  const canAccept = record.status !== EDeliveryCompanyDelivererStatus.ACCEPTED &&
    record.status !== EDeliveryCompanyDelivererStatus.PENDING_INVITATION

  return canAccept
    ? (
      <LoadingButton label='Accept' onClick={acceptHost} loading={loading}>
        <CheckIcon />
      </LoadingButton>
      )
    : null
}

const DeclineButton = ({ resource, record = {} }) => {
  const [decline, { loading }] = useMutation({
    type: 'update',
    resource,
    payload: {
      id: record.id,
      data: {
        status: EDeliveryCompanyDelivererStatus.DECLINED
      }
    }
  })
  const canDecline = record.status === EDeliveryCompanyDelivererStatus.PENDING_APPROVAL
  const declineHost = debounce(decline, 300)

  return canDecline
    ? (
      <LoadingButton label='Decline' onClick={declineHost} loading={loading}>
        <CancelOutlinedIcon />
      </LoadingButton>
      )
    : null
}

export const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      source='status'
      choices={statuses}
      alwaysOn
    />
    <ReferenceInput
      key='mobileUserIdFilterPhone'
      source='mobileUserId'
      label='Phone'
      reference='mobileUsers'
      filterToQuery={(v) => toRegex('phone')(v)}
      alwaysOn
    >
      <AutocompleteInput optionText='phone' />
    </ReferenceInput>
    <ReferenceInput
      source='mobileUserId'
      key='mobileUserIdFilterName'
      label='Name'
      reference='mobileUsers'
      filterToQuery={(v) => toRegex('name')(v)}
      alwaysOn
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
  </Filter>
)

const ListActions = (props) => (
  <Box display='flex' justifyContent='center'>
    <ShowButton {...props} />
    <AcceptButton {...props} />
    <ResendInviteButton {...props} />
    <DeclineButton {...props} />
    <DeleteButton {...props} />
  </Box>
)

export const DriverCreate = (props) => {
  const { data: deliveryCompany } = useDeliveryCompany()
  return (
    <Create
      {...props}
      title='Create Driver'
    >
      <SimpleForm>
        <TextInput
          source='name'
          label='Name'
          type='text'
          validate={required()}
        />
        <TextInput
          source='phone'
          label='Phone'
          type='text'
          validate={[required(), validatePhone()]}
        />
        <TextInput
          source='deliveryCompanyId'
          reference='deliveryCompanies'
          defaultValue={deliveryCompany?._id}
          style={{
            display: 'none'
          }}
        />
      </SimpleForm>
    </Create>
  )
}

export const DriverShow = (props) => (
  <Show
    {...props}
    title='Driver'
  >
    <SimpleShowLayout>
      <ReferenceField
        link={false}
        label='Name'
        source='mobileUserId'
        key='mobileUserName'
        reference='mobileUsers'
      >
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField
        link={false}
        label='Phone'
        source='mobileUserId'
        key='mobileUserPhone'
        reference='mobileUsers'
      >
        <TextField source='phone' />
      </ReferenceField>
      <SelectField
        source='status'
        label='Status'
        choices={statuses}
      />
    </SimpleShowLayout>
  </Show>
)

export const DriverListEmpty = () => {
  const { basePath, resource } = useListContext()
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No drivers available
      </Typography>
      <Typography variant='body1'>
        Create new one
      </Typography>
      <Box style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: 30,
        gap: 15
      }}
      >
        <CreateButton variant='contained' basePath={basePath} />
        <ImportButton filled resource={resource} />
      </Box>
    </Box>
  )
}
const TopToolbarActions = props => {
  const { className, basePath, resource } = props
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ImportButton resource={resource} />
    </TopToolbar>
  )
}

export const DriverList = (props) => {
  return (
    <List
      {...props}
      empty={<DriverListEmpty />}
      title='Drivers'
      filters={<Filters />}
      actions={<TopToolbarActions />}
    >
      <Datagrid>
        <ReferenceField
          source='mobileUserId'
          label='Name'
          reference='mobileUsers'
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField
          source='mobileUserId'
          label='Phone'
          reference='mobileUsers'
          link={false}
        >
          <TextField source='phone' />
        </ReferenceField>
        <SelectField
          source='status'
          label='Status'
          choices={statuses}
        />
        <ListActions />
      </Datagrid>
    </List>
  )
}
