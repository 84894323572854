import React from 'react'
import images from '../assets/index'

const Logo = ({ big, light }) => {
  const link = light ? images.logoLight : images.logoDark
  return (
    <img
      src={link}
      height={big ? '100px' : '40px'}
      style={{
        margin: '0px auto'
      }}
      alt='Buzzin'
    />
  )
}

export default Logo
