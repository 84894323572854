import { useState } from 'react'
import useUser from '../../../hooks/useUser'
import { useMutation, useNotify } from 'react-admin'

const usePassword = () => {
  const notify = useNotify()
  const {
    id
  } = useUser()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [save, { loading }] = useMutation({
    type: 'update',
    resource: 'users',
    payload: { id, data: { password } }
  })

  const savePassword = async (event) => {
    event.preventDefault()
    if (
      !password ||
      !repeatPassword
    ) {
      notify('messages.settings.passwordsRequired', 'error')
      return false
    }
    if (password !== repeatPassword) {
      notify('messages.settings.passwordsMatch', 'error')
      return false
    }
    save()
    setPassword('')
    setRepeatPassword('')
    notify('messages.settings.passwordSave')
  }

  return {
    savePassword,
    password,
    setPassword,
    repeatPassword,
    setRepeatPassword,
    loading
  }
}

export default usePassword
