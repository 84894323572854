import React from 'react'
import { Button } from 'react-admin'

import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingButton = ({ label, loading, onClick, children }) => (
  <Button label={label} onClick={onClick} disabled={loading}>
    {loading ? <CircularProgress size={16} /> : children}
  </Button>
)

export default LoadingButton
