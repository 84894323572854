import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import CompanyDetails from './components/CompanyDetails'
import ChangePassword from './components/ChangePassword'
import { Title } from 'react-admin'
import Subscriptions from './components/Subscriptions'

const Settings = () => {
  useEffect(() => {
    document.querySelector('#react-admin-title').textContent = 'Settings'
  })
  return (
    <>
      <Title title='Settings' />
      <CompanyDetails />
      <ChangePassword />
      <Subscriptions />
    </>
  )
}

export default withRouter(Settings)
