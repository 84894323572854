import useDeliveryCompany from '../../../hooks/useDeliveryCompany'

const useSubscription = () => {
  const { data } = useDeliveryCompany()

  return {
    activeUsers: data?.activeUsers ?? 0,
    maxUsers: data?.maxUsers ?? 0
  }
}

export default useSubscription
