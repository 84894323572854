// In theme.js
import { defaultTheme } from 'react-admin'
import merge from 'lodash/merge'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import createPalette from '@material-ui/core/styles/createPalette'

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    type: 'dark',
    primary: {
      light: '#757ce8',
      main: '#feaa14',
      dark: '#feaa14',
      contrastText: '#fff'
    },
    secondary: {
      light: '#feaa14',
      main: '#feaa14',
      dark: '#feaa14',
      contrastText: '#fff'
    },
    background: {
      default: '#222a3a',
      paper: '#fff'
    }
  })
)

const rawTheme = {
  palette,
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: 'none'
      },
      root: {
        backgroundClip: 'padding-box',
        color: '#000'
      }
    },
    MuiTableCell: {
      body: {
        color: '#000'
      },
      root: {
        borderBottomColor: '#e0dfdf'
      }
    },
    MuiTableSortLabel: {
      root: {
        color: '#000',
        '&:hover': {
          color: 'rgba(0,0,0,.7)'
        }
      },
      active: {
        color: '#000 !important'
      },
      icon: {
        color: 'grey !important'
      }
    },
    MuiListItemIcon: {
      root: {
        color: '#000',
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
          color: '#fff'
        }
      }
    },
    RaMenuItemLink: {
      icon: {
        color: '#fff'
      }
    },
    RaLogout: {
      menuItem: {
        color: '#000',
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
          color: 'rgba(255, 255, 255, 0.7)'
        }
      }
    },
    RaPaginationActions: {
      actions: {
        color: '#000'
      }
    },
    MuiTablePagination: {
      root: {
        color: '#000'
      },
      select: {
        color: '#000'
      }
    },
    MuiButton: {
      root: {
        color: '#000'
      },
      contained: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.3)',
          backgroundColor: 'rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: 'grey'
      }
    },
    MuiFormHelperText: {
      root: {
        color: '#000'
      }
    },
    MuiInputBase: {
      root: {
        color: 'inherit'
      }
    },
    RaToolbar: {
      toolbar: {
        backgroundColor: '#fff',
        borderTop: '1px solid #e0dfdf'
      }
    },
    MuiTypography: {
      colorTextSecondary: {
        color: 'inherit'
      }
    },
    MuiCheckbox: {
      root: {
        color: 'rgba(0,0,0,.7)'
      }
    }
  }
}

export const theme = createMuiTheme(
  merge({}, defaultTheme, rawTheme)
)
