import React from 'react'
import Logo from './Logo'
import { useTranslate } from 'react-admin'

export const FooterContainer = ({ children }) => {
  return (
    <footer style={{
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 100,
      padding: '10px 15px',
      backgroundColor: '#ffffff',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
    >
      {children}
    </footer>
  )
}

export const FooterLink = ({ children, ...props }) => {
  return (
    <a
      style={{
        margin: '0px 15px',
        color: '#000',
        textDecoration: 'none'
      }} {...props}
    >{children}
    </a>
  )
}

export const Footer = () => {
  const translate = useTranslate()
  return (
    <FooterContainer>
      <div>
        <FooterLink href={translate('messages.footer.privacyPolicyLink')}>
          {translate('messages.footer.privacyPolicy')}
        </FooterLink>
        <FooterLink href={translate('messages.footer.termsAndConditionsLink')}>
          {translate('messages.footer.termsAndConditions')}
        </FooterLink>
      </div>
      <Logo />
      <div>
        <FooterLink href={'https://' + translate('messages.footer.site')}>
          {translate('messages.footer.site')}
        </FooterLink>
        <FooterLink href={'mailto:' + translate('messages.footer.email')}>
          {translate('messages.footer.email')}
        </FooterLink>
      </div>
    </FooterContainer>
  )
}

export default Footer
