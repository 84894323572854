import React from 'react'
import { Admin, Resource } from 'react-admin'
import { authClient, restClient } from 'ra-data-feathers'

import { Route } from 'react-router-dom'

import PersonIcon from '@material-ui/icons/Person'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import feathersClient from './client/feathersClient'

import { DriverCreate, DriverList, DriverShow } from './models/drivers'

import Layout from './containers/Layout'
import LoginPage from './pages/Login'

import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Settings from './pages/Settings'

import Menu from './elements/Menu'

import i18nProvider from './i18nProvider'
import { theme } from './theme'
import { jwtStorageKey } from './constants'

const restClientOptions = {
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
}

const authClientOptions = {
  storageKey: jwtStorageKey,
  authenticate: { // Options included in calls to Feathers client.authenticate
    strategy: 'deliveryAdmin' // The authentication strategy Feathers should use
  },
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login', // Our example login form might be at '/login', redirect here if AUTH_CHECK fails
  permissionsField: 'user'
}

const customRoutes = [
  <Route key='forgotPassword' exact path='/forgotPassword' component={ForgotPassword} noLayout />,
  <Route key='resetPassword' exact path='/resetPassword' component={ResetPassword} noLayout />,
  <Route key='settings' exact path='/settings' component={Settings} />
]

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Admin
        menu={Menu}
        theme={theme}
        dataProvider={restClient(feathersClient, restClientOptions)}
        authProvider={authClient(feathersClient, authClientOptions)}
        locale='en'
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={LoginPage}
        customRoutes={customRoutes}
        dashboard={false}
      >
        <Resource
          name='deliveryCompanyDeliverer'
          options={{ label: 'Drivers' }}
          icon={PersonIcon}
          list={DriverList}
          create={DriverCreate}
          show={DriverShow}
        />
        <Resource name='mobileUsers' />
      </Admin>
    </MuiPickersUtilsProvider>
  )
}

export default App
